import { createWebHistory } from 'vue-router';
import { createLangRouter } from 'vue-lang-router';

import translations from '@/router/lang/translations';
import localizedURLs from '@/router/lang/localized_urls';

import routes from '@/router/routes';

import LoginByToken from '@/router/guards/LoginByToken';
import CampaignTags from '@/router/guards/CampaignTags';
import store from '@/store';

const langRouterOptions = {
  defaultLanguage: process.env.VUE_APP_FALLBACK_LOCALE,
  language: cookies.get('locale') || process.env.VUE_APP_FALLBACK_LOCALE,
  translations,
  localizedURLs,
};

const routerOptions = {
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
};

const router = createLangRouter(langRouterOptions, routerOptions);

router.beforeEach((to, from, next) => {
  store.dispatch('loader/SET_LOADER', true);
  next();
});

// RemoveTrailingSlash
router.beforeEach((to, from, next) => {
  const path = to.path;

  // If it's not the root and ends with a slash
  if (path !== '/' && path.endsWith('/')) {
    // Redirect to the version without trailing slash
    next({
      path: path.slice(0, -1),
      query: to.query,
      hash: to.hash,
      replace: true, // optional: avoid adding to browser history
    });
  } else {
    next();
  }
});

router.beforeEach(LoginByToken);
router.beforeEach(CampaignTags);

router.afterEach((to, from) => {
  store.dispatch('loader/SET_LOADER', false);
});

export default router;
